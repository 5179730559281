<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo auth-bg">
        <logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 overflow-hidden bg-white background-no-repeat bg-cover"
        :style="`background-image: url(${imgUrl}); background-position: center;`"
      />
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-lg-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('auth.reset-password.header') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('auth.reset-password.text') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="resetPassword">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="resetPassword"
            >

              <!-- password -->
              <b-form-group
                :label="$t('auth.reset-password.new-password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.reset-password.new-password')"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="new-password"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                :label="$t('auth.reset-password.confirm-new-password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.reset-password.confirm-new-password')"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                {{ $t('auth.reset-password.set-new-password') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('auth.back-to-log-in') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store'
import Logo from '@/views/_partials/Logo'
import router from '@/router'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/lessons/lesson_02.jpg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        // return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeMount() {
    this.isUserLoggedIn()
  },
  methods: {
    isUserLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$router.push('/')
        return true
      }
      this.loading = false
      return false
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    resetPassword() {
      if (this.isUserLoggedIn()) {
        return
      }

      this.processing = true
      this.$refs.resetPassword.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          const payload = {
            userId: this.$route.query.userId,
            token: this.$route.query.token,
            password: this.password,
          }
          this.$store.dispatch('auth/changePassword', payload)
            .then(() => {
              window.toast.notify.success(this.$t('auth.reset-password.success'))
            })
            .catch(response => {
              this.processing = false
              if (response.message === 'Token is invalid') {
                window.toast.notify.danger(this.$t('auth.reset-password.errors.invalid-token'))
              } else if (response.message === 'Token is out of date') {
                window.toast.notify.danger(this.$t('auth.reset-password.errors.token-out-of-date'))
              } else {
                window.toast.notify.danger(this.$t('auth.reset-password.errors.other'))
              }
            })
            .finally(() => router.push({ name: 'login' }))

          return true
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper .brand-logo {
  border-radius: 0.357rem;
  img {
    width: 200px;
    height: inherit;
    margin: 30px;
  }
}
</style>
